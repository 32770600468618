var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-3",attrs:{"no-body":""}},[_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',[_c('validation-observer',{ref:"formRef"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-1",attrs:{"label":_vm.$t('globalSingular.code'),"label-for":"code"}},[_c('b-form-input',{attrs:{"id":"code","size":"sm","placeholder":_vm.$t('globalSingular.auto'),"disabled":_vm.actions.isPreview},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{staticClass:"mb-1",attrs:{"label":_vm.$t('apps.masterDataOffice.singular.name')}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","size":"sm","state":errors.length > 0 ? false:null,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataOffice.placeholder.personName')  : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row'),_c('b-row',[(_vm.form.isGroup)?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataOffice.singular.groupContact')}},[_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"region","options":_vm.LOV.groupContact,"reduce":function (field) { return field.id; },"getOptionLabel":function (option) { return option.label; },"disabled":_vm.actions.isPreview,"placeholder":"Contact Group"},on:{"option:selected":_vm.handleRegion},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('small',[_vm._v(_vm._s(option.label))])]}}],null,false,2087015391),model:{value:(_vm.form.contactParentId),callback:function ($$v) {_vm.$set(_vm.form, "contactParentId", $$v)},expression:"form.contactParentId"}})],1)],1):_vm._e()],1),_c('div',{staticClass:"mt-1 d-flex"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"18"}}),_c('h6',{staticClass:"mb-0 ml-50"},[_vm._v(" Contact Person ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataOffice.singular.contactName')}},[_c('validation-provider',{attrs:{"name":"contactPerson"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"category-code","size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Contact Person Name","disabled":_vm.actions.isPreview},model:{value:(_vm.form.picName),callback:function ($$v) {_vm.$set(_vm.form, "picName", $$v)},expression:"form.picName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataOffice.singular.contactPersonPhone')}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" +62 ")]),_c('cleave',{staticClass:"form-control",attrs:{"id":"phone","raw":false,"options":_vm.options.phone,"disabled":_vm.actions.isPreview,"placeholder":"21 890 567"},model:{value:(_vm.form.picHandphone),callback:function ($$v) {_vm.$set(_vm.form, "picHandphone", $$v)},expression:"form.picHandphone"}})],1)],1)],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataOffice.singular.brand')}},[_c('validation-provider',{attrs:{"name":"Brand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"brand","size":"sm","state":errors.length > 0 ? false:null,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataOffice.placeholder.brandName')  : null,"disabled":_vm.actions.isPreview,"rows":"1"},model:{value:(_vm.form.brand),callback:function ($$v) {_vm.$set(_vm.form, "brand", $$v)},expression:"form.brand"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataOffice.singular.brandEmail')}},[_c('validation-provider',{attrs:{"name":"Brand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"brandEmail","size":"sm","state":errors.length > 0 ? false:null,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataOffice.singular.brandEmail')  : null,"disabled":_vm.actions.isPreview,"rows":"1"},model:{value:(_vm.form.brandEmail),callback:function ($$v) {_vm.$set(_vm.form, "brandEmail", $$v)},expression:"form.brandEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataOffice.singular.brandPhone')}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" +62 ")]),_c('cleave',{staticClass:"form-control",attrs:{"id":"phone","raw":false,"options":_vm.options.phone,"disabled":_vm.actions.isPreview,"placeholder":"21 890 567"},model:{value:(_vm.form.brandPhone),callback:function ($$v) {_vm.$set(_vm.form, "brandPhone", $$v)},expression:"form.brandPhone"}})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataOffice.singular.brandFax')}},[_c('validation-provider',{attrs:{"name":"Brand Fax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"select-size-sm",attrs:{"id":"brandFax","label":"name","size":"sm","state":errors.length > 0 ? false:null,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataOffice.placeholder.brandFax')  : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.brandFax),callback:function ($$v) {_vm.$set(_vm.form, "brandFax", $$v)},expression:"form.brandFax"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataOffice.singular.brandWebsite')}},[_c('validation-provider',{attrs:{"name":"Brand Website"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"brandWebsite","label":"name","size":"sm","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":"brand website"},model:{value:(_vm.form.brandWebsite),callback:function ($$v) {_vm.$set(_vm.form, "brandWebsite", $$v)},expression:"form.brandWebsite"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataOffice.singular.brandNpwp')}},[_c('validation-provider',{attrs:{"name":"Brand NPWP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"select-size-sm",attrs:{"id":"brandNpwp","size":"sm","label":"name","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":"brand npwp"},model:{value:(_vm.form.brandNpwp),callback:function ($$v) {_vm.$set(_vm.form, "brandNpwp", $$v)},expression:"form.brandNpwp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"mt-3 d-flex flex-sm-column flex-md-row justify-content-between"},[_c('b-button',{staticClass:"mb-sm-1 mb-md-0",attrs:{"variant":_vm.actions.isPreview ? 'outline-secondary' : 'outline-primary',"to":{ name: 'apps-master-data-office-list'}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.backToList'))+" ")],1),(_vm.actions.isPreview)?_c('b-button',{staticClass:" mb-sm-1 mb-md-0",attrs:{"variant":"outline-danger"},on:{"click":_vm.handleDelete}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.delete'))+" ")],1):_vm._e(),(!_vm.actions.isPreview)?_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.handleSubmit}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.actions.isEditMode ? _vm.$t('globalActions.update') : _vm.$t('globalActions.create'))+" ")],1):_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.changeToEdit}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.changeToEdit'))+" ")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }