<template>
  <b-card
    no-body
    class="p-3"
  >
    <b-row align-h="center">
      <b-col
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>

            <!-- contact -->
            <b-row>

              <b-col md="3">
                <b-form-group
                  :label="$t('globalSingular.code')"
                  class="mb-1"
                  label-for="code"
                >
                  <b-form-input
                    id="code"
                    size="sm"
                    v-model="form.code"
                    :placeholder="$t('globalSingular.auto')"
                    :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>

              <b-col md="5">
                <b-form-group
                  :label="$t('apps.masterDataOffice.singular.name')"
                  class="mb-1"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      size="sm"
                      v-model="form.name"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="!actions.isPreview ? $t('apps.masterDataOffice.placeholder.personName')  : null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>

            <!-- detail -->
            <b-row>

            </b-row>


            <b-row>

              <b-col md="4" v-if="form.isGroup">
                <b-form-group :label="$t('apps.masterDataOffice.singular.groupContact')">
                  <v-select
                    id="region"
                    v-model="form.contactParentId"
                    :options="LOV.groupContact"
                    :reduce="field => field.id"
                    :getOptionLabel="option => option.label"
                    :disabled="actions.isPreview"
                    @option:selected="handleRegion"
                    placeholder="Contact Group"
                    class="select-size-sm">
                    <template v-slot:option="option">
                      <small>{{ option.label }}</small>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

            </b-row>

            <!-- Header: contact Person -->
            <div class="mt-1 d-flex">
              <feather-icon
                icon="UserIcon"
                size="18"
              />
              <h6 class="mb-0 ml-50">
                Contact Person
              </h6>
            </div>

            <!-- contact Person-->
            <b-row class="mt-1">
              <b-col md="6">
                <b-form-group :label="$t('apps.masterDataOffice.singular.contactName')">
                  <validation-provider
                    #default="{ errors }"
                    name="contactPerson"
                  >
                    <b-form-input
                      id="category-code"
                      size="sm"
                      v-model="form.picName"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Contact Person Name"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>


              <b-col md="6">
                <b-form-group :label="$t('apps.masterDataOffice.singular.contactPersonPhone')">
                  <b-input-group
                      size="sm">
                    <b-input-group-prepend is-text>
                      +62
                    </b-input-group-prepend>
                    <cleave
                      id="phone"
                      v-model="form.picHandphone"
                      class="form-control"
                      :raw="false"
                      :options="options.phone"
                      :disabled="actions.isPreview"
                      placeholder="21 890 567"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- <b-col md="4">
                <b-form-group :label="$t('apps.masterDataOffice.singular.brand')">
                  <b-input-group
                      size="sm">
                    <cleave
                      id="brand"
                      v-model="form.brand"
                      class="form-control"
                      :raw="false"
                      :options="options.brand"
                      :disabled="actions.isPreview"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col> -->
            </b-row>

            <!-- Header: Brand -->
            <!-- <div class="mt-1 d-flex justify-content-between">
              <h6 class="mb-0 ml-50">
                {{ $t('apps.masterDataOffice.singular.brand') }}
              </h6>

            </div> -->


            <!-- Address -->
            <b-row class="mt-1">
              <b-col md="6">
                <b-form-group :label="$t('apps.masterDataOffice.singular.brand')">
                  <validation-provider
                    #default="{ errors }"
                    name="Brand"
                  >
                    <b-form-input
                      id="brand"
                      size="sm"
                      v-model="form.brand"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="!actions.isPreview ? $t('apps.masterDataOffice.placeholder.brandName')  : null"
                      :disabled="actions.isPreview"
                      rows="1"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>

                </b-form-group >
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('apps.masterDataOffice.singular.brandEmail')">
                  <validation-provider
                    #default="{ errors }"
                    name="Brand"
                  >
                    <b-form-input
                      id="brandEmail"
                      size="sm"
                      v-model="form.brandEmail"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="!actions.isPreview ? $t('apps.masterDataOffice.singular.brandEmail')  : null"
                      :disabled="actions.isPreview"
                      rows="1"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>

                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('apps.masterDataOffice.singular.brandPhone')">
                <b-input-group
                      size="sm">
                    <b-input-group-prepend is-text>
                      +62
                    </b-input-group-prepend>
                    <cleave
                      id="phone"
                      v-model="form.brandPhone"
                      class="form-control"
                      :raw="false"
                      :options="options.phone"
                      :disabled="actions.isPreview"
                      placeholder="21 890 567"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- <b-col md="5">
                <b-form-group :label="$t('apps.masterDataOffice.singular.billingRegionCode')">
                  <validation-provider
                    #default="{ errors }"
                    name="Region"
                  >
                    <v-select
                      id="region"
                      v-model="form.billingRegionCode"
                      :options="LOV.regions"
                      :reduce="field => field.code"
                      :getOptionLabel="option => option.province.name && option.name"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      @option:selected="handleRegion"
                      placeholder="City"
                      class="select-size-sm">
                    <template v-slot:option="option">
                      <small>{{ option.name }} ( {{ option.province.name }} )</small>
                    </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
              <b-col md="6">
                <b-form-group :label="$t('apps.masterDataOffice.singular.brandFax')">
                  <validation-provider
                    #default="{ errors }"
                    name="Brand Fax"

                  >
                    <b-form-input
                      id="brandFax"
                      v-model="form.brandFax"
                      label="name"
                      size="sm"
                      :state="errors.length > 0 ? false:null"
                      class="select-size-sm"
                      :placeholder="!actions.isPreview ? $t('apps.masterDataOffice.placeholder.brandFax')  : null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('apps.masterDataOffice.singular.brandWebsite')">
                  <validation-provider
                    #default="{ errors }"
                    name="Brand Website"

                  >
                    <b-form-input
                      id="brandWebsite"
                      v-model="form.brandWebsite"
                      label="name"
                      size="sm"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      placeholder="brand website"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('apps.masterDataOffice.singular.brandNpwp')">
                  <validation-provider
                    #default="{ errors }"
                    name="Brand NPWP"

                  >
                    <b-form-input
                      id="brandNpwp"
                      v-model="form.brandNpwp"
                      size="sm"
                      label="name"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="select-size-sm"
                      placeholder="brand npwp"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>

            <!-- Header: Finance Accounting -->
            <!-- <div class="mt-1 d-flex justify-content-between">
              <h6 class="mb-0 ml-50"> <feather-icon
                icon="TrendingUpIcon"
                size="18"
              />
                {{ $t('apps.masterDataOffice.singular.finance') }}
              </h6>
            </div> -->


            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-master-data-office-list'}"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
                {{ $t('globalActions.delete') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>

              <b-button
                v-else
                variant="outline-primary"
                @click="changeToEdit"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('globalActions.changeToEdit') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>

</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
// import i18n from '@/libs/i18n'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
  VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useLocalization from '@/comp-functions/utils/useLocalization'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'
import VuexStore from '@/store'

import 'cleave.js/dist/addons/cleave-phone.us'

import useFormResource from '@/comp-functions/useFormResource'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    Cleave
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  setup () {
    const {
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'master/offices', localeContextPath: 'apps.masterDataOffice.singular.office', redirectPathName: 'apps-master-data-office' })

    const company = computed(() => {
      return VuexStore.state.user.currentCompany
    })

    // LOV (select option)
    const LOV = ref({
      branches: [],
      offices: [],
      districs: [],
      regions: [],
      provinces: [],
      groupContact: [],
      accountAR: [],
      accountAP: [],
      accounts:[]
    })

    const { $t } = useLocalization()

    const form = ref({
      code: null,
      name: null,
      branchId: null,
      picName: null,
      picHandphone: null,
      brand: null,
      brandEmail: null,
      brandPhone:null,
      brandFax: null,
      brandWebsite: null,
      brandNpwp: null
    })

    // Add row bankInformation
    const addRow = () => {
      form.value.bankInformation.push({ bankName: '', bankAccountName: null, bankOffice: null, bankAccountNumber: null })
    }

    // Remove row bankInformation
    const removeRow =  (index) => {
      if (form.value.bankInformation.length <= 1) return false
      form.value.bankInformation.splice(index, 1)
    }

    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await show()
      form.value.code                   = data.code
      form.value.name                   = data.name
      form.value.brand                  = data.brand
      form.value.branchId               = data.branch_id
      form.value.brandEmail             = data.brand_email
      form.value.brandPhone             = data.brand_phone
      form.value.brandWebsite           = data.brand_website
      form.value.brandNpwp              = data.brand_npwp
      form.value.brandFax               = data.brand_fax
      form.value.picName                = data.pic_name
      form.value.picHandphone           = data.pic_handphone

    }

    const getProvinces = async (params = '') => {
      LOV.value.provinces = await get({ url: `data/province${params}`})
    }
    const getRegions = async () => {
      LOV.value.regions = await get({ url: 'data/region'})
    }
    const getDistricts = async () => {
      LOV.value.districs = await get({ url: 'data/district'})
    }

    const getGroupContact = async () => {
      LOV.value.groupContact = await get({ url: 'value/contact/group'})
    }

    const getAccountAP = async () => {
      LOV.value.accountAP = await get({ url: 'master/accounts/child?account_subtype_code=AP'})
    }

    const getAccountAR = async () => {
      LOV.value.accountAR = await get({ url: 'master/accounts/child?account_subtype_code=AR'})
    }

    const handleRegion = ({province_code}) => {
      form.value.billingProvinceCode = province_code
    }

    const handleProvince = () => {
      form.value.billingRegionCode = null
    }

    // Get offices
    const getOffice = async () => {
      LOV.value.offices = await get({ url: 'master/offices'})
    }
    // Get Branches
    const getBranch = async () => {
      LOV.value.branches = await get({ url: 'master/branches'})
    }

    const tableColumns = ref([])

    onMounted(() => {
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
      if (company.value.plan_id > 2) {
        getOffice()
        getBranch()
      }
      getGroupContact()
      getProvinces()
      getRegions()
      getDistricts()
      getAccountAR()
      getAccountAP()
      tableColumns.value = [
        { key: 'bankName', label: $t('apps.masterDataOffice.singular.bankName'), width: 'auto', thClass: 'bg-transparent pl-0', tdClass: 'align-top pl-0' },
        { key: 'bankAccountName', label: $t('apps.masterDataOffice.singular.bankAccountName'), width: 'auto', thClass: 'bg-transparent', tdClass: 'align-top'},
        { key: 'bankOffice', label: $t('apps.masterDataOffice.singular.bankOffice'), width: '24%', thClass: 'bg-transparent width-25-per', tdClass: 'align-top'},
        { key: 'bankAccountNumber', label: $t('apps.masterDataOffice.singular.bankAccountNumber'), width: '24%', thClass: 'bg-transparent width-25-per', tdClass: 'align-top'},
        { key: 'actions', width: '12%', thClass: 'bg-transparent text-right width-10-per  pr-0', tdClass: 'align-top text-right pr-0'}
      ]
    })

    return {
      company,
      handleRegion,
      handleProvince,
      required,
      form,
      addRow,
      removeRow,
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store,
      selected: [],
      tableColumns,
      LOV,
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'ID'
        },
        type :
        [
          { item: 'CTCS', name: 'Customer' },
          { item: 'CTSP', name: 'Supplier' },
          { item: 'CTAG', name: 'Agent'}
        ],
        group : [{ item: true, name: 'Group Contact' }]
      }
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    handleSubmit () {
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
